import { userConstants } from '../_constants'

const userInfo = localStorage.getItem('userinfo')
const stats = localStorage.getItem('statics')
let AllStatics = JSON.parse(stats ?? '{}') as Statics

if (stats && userInfo) {
    let data = Object.create({})
    for (let key in AllStatics) {
        // @ts-ignore
        data[key] = AllStatics[key as keyof typeof AllStatics].map(
            (item: StaticsValues) => item.name,
        )
    }
    let selectedLangs: string[] = []
    let coach = JSON.parse(userInfo ?? '{}') as Coach
    coach.user.nativeLanguages.forEach((item) => {
        AllStatics.languages.forEach((item2) => {
            if (item2.key === item) {
                selectedLangs.push(item2.name)
            }
        })
    })
    const selectedCoachLangs: string[] = []
    coach.coachingLanguages.forEach((item) => {
        AllStatics.languages.forEach((item2) => {
            if (item2.key === item) {
                selectedCoachLangs.push(item2.name)
            }
        })
    })
    let highestEducationLevel = ''
    AllStatics.educationLevels.forEach((item2) => {
        if (item2.key === coach.highestEducationLevel) {
            highestEducationLevel = item2.name
        }
    })
    let selectedCountry = ''
    AllStatics.countries.forEach((item2) => {
        if (item2.key === coach.user?.address?.country) {
            selectedCountry = item2.name
        }
    })
    const experienceFunctions: string[] = []
    coach.experienceFunctions.forEach((item) => {
        AllStatics.functions.forEach((item2) => {
            if (item2.key === item) {
                experienceFunctions.push(item2.name)
            }
        })
    })

    const experienceFunctionsCoaching: string[] = []
    coach.coachingFunctions.forEach((item) => {
        AllStatics.functions.forEach((item2) => {
            if (item2.key === item) {
                experienceFunctionsCoaching.push(item2.name)
            }
        })
    })

    const experienceIndustries: string[] = []
    coach.experienceIndustries.forEach((item) => {
        AllStatics.industries.forEach((item2) => {
            if (item2.key === item) {
                experienceIndustries.push(item2.name)
            }
        })
    })

    const experienceIndustriesCoaching: string[] = []
    coach.coachingIndustries.forEach((item) => {
        AllStatics.industries.forEach((item2) => {
            if (item2.key === item) {
                experienceIndustriesCoaching.push(item2.name)
            }
        })
    })
    const experienceCountry: string[] = []
    coach.experienceCountry.forEach((item) => {
        AllStatics.countries.forEach((item2) => {
            if (item2.key === item) {
                experienceCountry.push(item2.name)
            }
        })
    })
    const areasCompetencies: string[] = []
    coach.areasCompetencies.forEach((item) => {
        AllStatics.competencies.forEach((item2) => {
            if (item2.key === item) {
                areasCompetencies.push(item2.name)
            }
        })
    })

    const coachingAccreditations: string[] = []
    coach.coachingAccreditations.forEach((item) => {
        AllStatics.accreditations.forEach((item2) => {
            if (item2.key === item) {
                coachingAccreditations.push(item2.name)
            }
        })
    })

    const coachTrainingTopics: string[] = []
    coach.profileInformation?.trainingTopics?.forEach((item) => {
        AllStatics.trainingTopics.forEach((item2) => {
            if (item2.key === item) {
                coachTrainingTopics.push(item2.name)
            }
        })
    })

    const coachingSpecialitiesClients: string[] = []
    coach.profileInformation?.coachingSpecialitiesClient?.forEach((item) => {
        AllStatics.coachingSpecialitiesClient.forEach((item2) => {
            if (item2.key === item) {
                coachingSpecialitiesClients.push(item2.name)
            }
        })
    })

    let coachingExperience = ''
    AllStatics?.teamCoachingExperience?.forEach((item2) => {
        if (item2.key === coach?.profileInformation?.coachingExperience) {
            coachingExperience = item2.name
        }
    })

    let coachingCredentials = ''
    const credentialKey = coach?.profileInformation?.coachingCredentials
    if (credentialKey) {
        coachingCredentials =
            AllStatics?.teamCoachingCredential?.find(
                (item2) => item2.key === credentialKey,
            )?.name || ''
    }
    const coachingMentorings: string[] = []
    coach.profileInformation?.coachingMentoring?.forEach((item) => {
        AllStatics.coachingMentoring.forEach((item2) => {
            if (item2.key === item) {
                coachingMentorings.push(item2.name)
            }
        })
    })

    const coachingSpecialityTopics: string[] = []
    coach.profileInformation?.coachingSpecialitiesTopics?.forEach((item) => {
        AllStatics.coachingSpecialitiesTopics.forEach((item2) => {
            if (item2.key === item) {
                coachingSpecialityTopics.push(item2.name)
            }
        })
    })

    const coachingCertifications: string[] = []
    coach.profileInformation?.coachingCertifications?.forEach((item) => {
        AllStatics.assessmentCertifications.forEach((item2) => {
            if (item2.key === item) {
                coachingCertifications.push(item2.name)
            }
        })
    })

    const interestedInCollaboration =
        coach.profileInformation.interestedInCollaboration ?? ''

    const coachingMemberships: string[] = []
    coach.coachingMemberships.forEach((item) => {
        AllStatics.memberships.forEach((item2) => {
            if (item2.key === item) {
                coachingMemberships.push(item2.name)
            }
        })
    })
    const coachingIndustries: string[] = []
    coach.coachingIndustries.forEach((item) => {
        AllStatics.industries.forEach((item2) => {
            if (item2.key === item) {
                coachingIndustries.push(item2.name)
            }
        })
    })
    const coachingFunctions: string[] = []
    coach.coachingFunctions.forEach((item) => {
        AllStatics.functions.forEach((item2) => {
            if (item2.key === item) {
                coachingFunctions.push(item2.name)
            }
        })
    })

    AllStatics = {
        ...AllStatics,
        statics: data,
        selectedLangs,
        coachTrainingTopics,
        selectedCoachLangs,
        highestEducationLevel,
        selectedCountry,
        experienceIndustries,
        experienceIndustriesCoaching,
        interestedInCollaboration,
        experienceFunctions,
        experienceFunctionsCoaching,
        experienceCountry,
        areasCompetencies,
        coachingAccreditations,
        coachingMemberships,
        coachingIndustries,
        coachingFunctions,
        coachingSpecialitiesClients,
        coachingSpecialityTopics,
        coachingCertifications,
        coachingMentorings,
        coachingExperience,
        coachingCredentials,
    }
}

export function statics(
    state = AllStatics,
    action: { type: string; payload: any },
) {
    switch (action.type) {
        case userConstants.GET_STATICS:
            return { ...action.payload }
        default:
            return state
    }
}
